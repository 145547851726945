<template>
  <main class="chart-container">
    <section class="chart-label">
      <p class="-stb">{{ title }}</p>
      <div class="chart-label-info">
        <p class="-ntb">{{ count }}</p>
        <div class="status-container" v-if="!hideNotes">
          <p class="-xstr">
            <i
              :class="[rating ? 'i-arrow-up-success' : 'i-arrow-down-fail']"
            ></i>
            <span :class="[rating ? 'status-success' : 'status-fail']">
              {{ pvalue }}</span
            >
            vs last month
          </p>
        </div>
      </div>
    </section>
    <section class="chart-graph">
      <div id="chart">
        <apexchart
          type="area"
          :height="height"
          :options="chartOptions"
          :series="series"
        ></apexchart>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  props: [
    "title",
    "count",
    "pvalue",
    "rating",
    "dataValues",
    "hideNotes",
    "height",
  ],
  data() {
    return {
      success: "#12b76a",
      fail: "#eb5757",
      //THIS IS THE CHART OBJECT
      series: [
        {
          name: "members",
          data: this.dataValues,
        },
      ],
      chartOptions: {
        chart: {
          height: "100%",
          type: "area",
          toolbar: {
            show: false,
          },
        },
        xaxis: {
          categories: ["", "", "", ""],
          labels: { show: false },
          axisBorder: {
            show: false,
          },
        },
        yaxis: { show: false },
        grid: { show: false },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
          width: 2,
        },
        colors: [""],
        fill: {
          shade: "light",
          colors: [""],
          gradient: {
            gradientToColors: [""],
            inverseColors: false,
            opacityFrom: 0.5,
            opacityTo: 0,
          },
        },
      },
    };
  },
  created() {
    if (this.rating) {
      this.chartOptions.colors[0] = this.success;
      this.chartOptions.fill.colors[0] = this.success;
      this.chartOptions.fill.gradient.gradientToColors[0] = this.success;
      this;
    } else {
      this.chartOptions.colors[0] = this.fail;
      this.chartOptions.fill.colors[0] = this.fail;
      this.chartOptions.fill.gradient.gradientToColors[0] = this.fail;
    }
  },
};
</script>

<style scoped>
.status-succcess {
  color: var(--success);
}
.status-fail {
  color: var(--error);
}
.fail {
  color: var(--fail);
}
.chart-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  --myColor: black;
}
.chart-graph {
  width: 80%;
  height: 100%;
}

.chart-label {
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  /* flex-wrap: nowrap; */
  justify-content: space-between;
  align-items: flex-start;
  /* white-space: nowrap; */
  height: 100%;
  /* border: 1px solid red; */
}
.chart-label-info {
  height: 60%;
  max-width: 44px;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: flex-end;
  justify-content: space-between;
  gap: 4px;
}
.status-container {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  text-align: left;
}
.status-container i {
  width: 12px;
  height: 12px;
}
i ~ span {
  margin-left: 4px;
}
#chart {
  height: 100%;
}
</style>